import React, { FC } from 'react';

import { Title, Paragraph } from '../../components/Typography';
import { ContentBanner } from '../../components/ContentBanner';

const DescSection: FC<{}> = () => {
  return (
    <ContentBanner>
      <Title>
        Premium photoreal animations and renderings that make your products
        stand out
      </Title>
      <Paragraph>
        We at Nimble Viz create full 3D CGI visualizations that help you launch
        and sell your products. From electronics to home appliances and
        everything in between.
      </Paragraph>
    </ContentBanner>
  );
};

export default DescSection;
