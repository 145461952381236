import styled from 'styled-components';

export const FormWrapper = styled.div`
  margin: 0 auto;
  max-width: 600px;
  padding-bottom: 54px;
`;

export const Input = styled.input`
  font-family: 'Quicksand', sans-serif;
  display: inline-block;
  width: calc(100% - 2rem);
  font-size: 1rem;
  color: #3d3d3d;
  background-color: #fff;
  border: 0;
  border: 1px solid #d1d1d1;
  border-radius: 4px;
  padding: 0.2rem 1rem 0;
  margin: 8px 0;
  height: 3.6em;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-appearance: none;
  -webkit-transition: all 0.15s;
  -o-transition: all 0.15s;
  transition: all 0.15s;
`;

export const Option = styled.li`
  list-style: none;
`;

export const RadiusOption = styled.div`
  text-align: left;
`;

export const StyledButton = styled.button`
  float: right;
  cursor: pointer;
  color: #fff;
  letter-spacing: 0.025em;
  font-size: 1rem;
  border: 0;
  border-radius: 4px;
  padding: 1.1em 2.28em 1em;
  background-color: #000;
  text-decoration: none;
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
`;

export const SuccessMessage = styled.p``;

export const ErrorMessage = styled.p`
  color: #dd1e39;
`;
