import styled from 'styled-components';
import Img from 'gatsby-image';

export const FluidImg = styled(Img)`
  flex: 0.5;
  z-index: -1;
  transition: all 0.2s ease-in;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 30px 60px -10px,
    rgba(0, 0, 0, 0.33) 0px 18px 36px -18px;

  @media (max-width: 768px) {
    box-shadow: rgba(0, 0, 0, 0.15) 0px 30px 60px -10px,
      rgba(0, 0, 0, 0.2) 0px 18px 36px -18px;
  }
`;
