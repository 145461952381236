import styled, { css } from "styled-components"

import { device } from "../../helpers/media-query"

export const TitleSize = css`
  font-size: 50px;
  margin: 14px 0;

  @media ${device.tablet} {
    font-size: 30px;
  }
`

export const Title = styled.p`
  ${TitleSize}
`

export const Paragraph = styled.p`
  font-size: 18px;
  margin: 0;

  @media ${device.tablet} {
    font-size: 16px;
  }
`

export const H1 = styled.h1`
  ${TitleSize}
`

export const H2 = styled.h2`
  ${TitleSize}
`
