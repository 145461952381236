import React, { FC, useState } from 'react';
import addToMailchimp from 'gatsby-plugin-mailchimp';

import {
  FormWrapper,
  Input,
  Option,
  RadiusOption,
  StyledButton,
  SuccessMessage,
  ErrorMessage,
} from './styles';
import { ContentBanner } from '../ContentBanner';
import { Title } from '../Typography';

const DEFAULT_STATE = {
  NAME: '',
  EMAIL: '',
  COMPANY: '',
  HAVECAD: '',
  MESSAGE: '',
};

const ContactForm: FC<{}> = () => {
  const [info, setInfo] = useState(DEFAULT_STATE);

  const [message, setMessage] = useState<string>('');
  const [success, setSuccess] = useState<boolean>(false);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    addToMailchimp(info.EMAIL, info)
      .then(data => {
        if (data.result === 'error') {
          console.log('data', data);
          data.msg.startsWith(`${info.EMAIL} is already subscribed`)
            ? setMessage(
                'You have already sent a message, please wait for a response or contact us at contact@nimbleviz.com.'
              )
            : setMessage(
                'An unexpected error occurred, please contact us at contact@nimbleviz.com.'
              );

          setSuccess(false);
        } else {
          setSuccess(true);
          setMessage('Your message was sent!');
          setInfo(DEFAULT_STATE);
        }
      })
      .catch((error: Error) => {
        console.error('error', error);
      });
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.persist();
    setInfo(state => ({
      ...state,
      [event.target.name]: event.target.value,
    }));
    setMessage('');
  };

  return (
    <ContentBanner id="contact">
      <form onSubmit={handleSubmit}>
        <Title>Get in touch!</Title>
        <FormWrapper>
          <Input
            type="text"
            value={info.NAME}
            name="NAME"
            placeholder="Name *"
            id="mce-NAME"
            pattern="[a-zA-Z ]+"
            required
            onChange={handleChange}
          />
          <Input
            type="email"
            value={info.EMAIL}
            name="EMAIL"
            placeholder="Email *"
            id="mce-EMAIL"
            pattern="^[a-zA-Z0-9-\_.]+@[a-zA-Z0-9-\_.]+\.[a-zA-Z0-9.]{2,5}$"
            required
            onChange={handleChange}
          />
          <Input
            type="text"
            value={info.COMPANY}
            name="COMPANY"
            placeholder="Company"
            id="mce-COMPANY"
            onChange={handleChange}
          />
          <RadiusOption>
            Do you have 3D CAD files for the product?
            <ul>
              <Option>
                <input
                  type="radio"
                  value="Yes"
                  name="HAVECAD"
                  id="mce-HAVECAD-0"
                  onChange={handleChange}
                />
                <label htmlFor="mce-HAVECAD-0">Yes</label>
              </Option>
              <Option>
                <input
                  type="radio"
                  value="No"
                  name="HAVECAD"
                  id="mce-HAVECAD-1"
                  onChange={handleChange}
                />
                <label htmlFor="mce-HAVECAD-1">No</label>
              </Option>
            </ul>
          </RadiusOption>
          <Input
            type="text"
            value={info.MESSAGE}
            name="MESSAGE"
            placeholder="Message *"
            id="mce-MESSAGE"
            required
            onChange={handleChange}
          />
          <StyledButton type="submit">Send</StyledButton>
        </FormWrapper>
      </form>
      {success ? (
        <SuccessMessage>{message}</SuccessMessage>
      ) : (
        <ErrorMessage>{message}</ErrorMessage>
      )}
    </ContentBanner>
  );
};

export default ContactForm;
