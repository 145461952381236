import React, { FC } from "react"
import VideoBanner from "../../../assets/ReelForWebsite.mp4"

import { VideoWrapper, BackgroundVideo } from "./styles"

const Video: FC<{}> = () => {
  return (
    <VideoWrapper>
      <BackgroundVideo autoPlay muted loop>
        <source src={VideoBanner} type="video/mp4"></source>
      </BackgroundVideo>
    </VideoWrapper>
  )
}

export default Video
