import React, { FC } from "react"

import Content from "./Content"
import Image from "./Image"

import { Card } from "./styles"
import { cards } from "./utils"

const Gallery: FC<{}> = () => {
  return (
    <>
      {cards.map((card, i) => (
        <Card key={i}>
          <Content title={card.title} description={card.description} />
          <Image img={card.image} />
        </Card>
      ))}
    </>
  )
}

export default Gallery
