import styled from 'styled-components';

export const Center = styled.div`
  width: 50%;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const ItemWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const ItemText = styled.div`
  flex: 1;
  text-align: left;

  @media (max-width: 768px) {
    text-align: center;
  }
`;

export const Title = styled.p`
  text-size: 20px;
  text-transform: uppercase;
`;

export const Desc = styled.p``;
