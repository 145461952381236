export const cards = [
  {
    title: "EXPLOSION OF DETAIL.",
    description:
      "Use exploded, break-apart, and section views to show your customers what your product is made of.",
    image: "detail",
  },
  {
    title: "CREATE DRAMA.",
    description:
      "Use light, movement, and cinematography to tease and engage your customers.",
    image: "drama",
  },
  {
    title: "DEFY THE LAWS OF GRAVITY.",
    description:
      "In the world of computer generated imagery (CGI), the only limit is your imagination. Make things fly, float, colide, move. In the world of 3D, you rule.",
    image: "gravity",
  },
  {
    title: "SHOW ALL THE OPTIONS.",
    description:
      "Use animation to communicate to your customers all the different variations of your product.",
    image: "options",
  },
  {
    title: "GET UP CLOSE.",
    description:
      "Let your customers get up close and personal with your product.",
    image: "closeup",
  },
  {
    title: "GET REAL. PHOTOREAL.",
    description:
      "Use physically-based materials and real-world camera depth-of-field and lens distortions to ground your images to reality.",
    image: "real",
  },
  {
    title: "SHOW HOW IT WORKS.",
    description:
      "Use animation to show your product's functionality and make it intuitive to you customer how it works.",
    image: "function",
  },
  {
    title: "CREATE A VIRTUAL WORLD.",
    description:
      "Want to show your product in its environment? No problem. We create realistic virtual worlds too, no matter the scale.",
    image: "studio",
  },
  {
    title: "ANIMATE IN THE STUDIO.",
    description:
      "Use animation and professional studio lighting to showcase your products elegantly.",
    image: "virtual",
  },
  {
    title: "MAKE IT SIZZLING HOT.",
    description:
      "Use visual effects to simulate fire, steam, and smoke to show that your product is fire.",
    image: "hot",
  },
  {
    title: "MAKE A SPLASH.",
    description:
      "Use water or any other fluid to show that your product is water resistant. Or just plain refreshing.",
    image: "water",
  },
]
